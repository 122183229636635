// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('active');
        }
        //else {
        //$element.removeClass('active');
        //}
    });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// SCROLL FADE HEADER - Slideshow

var viewHeight = window.innerHeight;
var header = $(".slideshow");
$(window).scroll(function (event) {
  var scroll = $(window).scrollTop();
  var calcul = (scroll/500);
  $(".slideshow").css("opacity",1-calcul);
});

// SCROLL TO ANCHOR PRODUCT
$('a[href^="#"]').on('click',function (e) {
    console.log('link-#');
    e.preventDefault();

    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top-60
    }, 1200, 'swing', function () {
        //window.location.hash = target;
    });
});

(function() {

    var s = {

        menuState: function() {

            $(this).toggleClass("cross");
            $(".main-nav-wrapper").toggleClass("open");

        },

        headerHeight: function() {

            if ($(window).scrollTop() > 0) {

                return $('.main-header').height();
            } else {

                return $('.main-header').height() - 24;
            }
        },

        headerPosition: function() {

            var top = $(window).scrollTop();

            if (top > 300) {

                $(".main-header").addClass("min");
                $(".sub-nav").addClass("min");
            } else {

                $(".main-header").removeClass("min");
                $(".sub-nav").removeClass("min");
            }
        },

        events: function() {
            $(window).scroll(this.headerPosition);
            $("#btnMobile").on("click", this.menuState);
        },

        init: function() {

            this.headerPosition();
            this.events();
        }

    };

    s.init();

})();

$(document).ready(function(){
  $('.product-grid .content-wrapper .products.home').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }]
  });

  $('.testimonials-grid .container >.content').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  });

});
